<template>
  <div v-if="flight" class="d-flex justify-content-between px20 w-100 fs16"
  :class="{'flex-column': !flight.return_routes || !firstReturnRoute}">
    <div class="text-center">
      <p>
        <b>{{ firstReturnRoute ? firstToRoute.from.city_name : lastToRoute.to.city_name }}</b> ({{ firstReturnRoute ? firstToRoute.from.airport_code : lastToRoute.to.airport_code }})
      </p>
      <p>
        <b>{{ firstToRoute.departure_date_local | flightsFormatDate }}</b>
      </p>
      <div class="d-flex justify-content-center fs12">
        <img src="/assets/img/icons/plane-departure-light-black.svg" alt="" class="mx5">
        {{ firstToRoute.departure_date_local | flightsFormatTime }}
      </div>
      <div class="df-c">
        <img :src="'/assets/img/icons/' + (flight.to_routes.length > 1 ? 'flight connection' : 'flightDirect') + '.svg'" alt="">
      </div>
    </div>
    <div class="df-c flex-column pt15">
      <img v-if="firstReturnRoute && flight.return_routes && flight.return_routes.length > 0" src="/assets/img/icons/flight.svg" alt="">
      <p v-if="firstReturnRoute && flight.return_routes && flight.return_routes.length > 0">{{ flight.days_in_dest }} {{ $t( "FLIGHTS.DAYS" ) }}</p>
      <div>
        <slot name="action-part"></slot>
      </div>
    </div>
    <div v-if="firstReturnRoute && flight.return_routes && flight.return_routes.length > 0" class="text-center">
      <p>
        <b>{{ firstReturnRoute.from.city_name }}</b> ({{ firstReturnRoute.from.airport_code }})
      </p>
      <p>
        <b>{{ firstReturnRoute.departure_date_local | flightsFormatDate }}</b>
      </p>
      <div class="d-flex justify-content-center fs12">
        <img src="/assets/img/icons/plane-departure-light-black.svg" alt="" class="mx5">
        {{ firstReturnRoute.departure_date_local | flightsFormatTime }}
      </div>
      <div class="df-c">
        <img :src="'/assets/img/icons/' + (flight.return_routes.length > 1 ? 'flight connection' : 'flightDirect') + '.svg'" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import RateStar from '../RateStar.vue';
export default {
  name: "FlightDesData",
  props: {
    flight: {
      type: Object,
      default: null
    }
  },
  components: {
    RateStar,
  },
  methods: {
  },
  computed: {
    firstToRoute() {
      return this.flight.to_routes && this.flight.to_routes.length ? this.flight.to_routes[0] : null;
    },
    lastToRoute() {
      return this.flight.to_routes && this.flight.to_routes.length ? this.flight.to_routes[this.flight.to_routes.length - 1] : null;
    },
    firstReturnRoute() {
      return this.flight.return_routes && this.flight.return_routes.length ? this.flight.return_routes[0] : null;
    },
  }
}
</script>

<style lang="scss" scoped>

</style>